import { Button } from "@material-ui/core"
import React, { useContext, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCloudUploadAlt,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import LoginStateContext from "../contexes/LoginStateContext"
import { withTranslation } from "react-i18next"
import { accessToken } from "../services/moocfi"

const Container = styled.div`
  background: white;
  border-radius: 1rem;
  box-shadow: 0 8px 40px -12px rgba(0, 0, 0, 0.3) !important;
  padding-bottom: 0.5rem;
  margin: 2rem 0;
`

const Header = styled.div`
  background: #d23d48;
  color: white;
  font-weight: bold;
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  font-size: 1.5rem;
`

const Description = styled.div`
  margin: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`

const SubmitArea = styled.form`
  display: grid;
  padding: 0 2rem;
  margin-bottom: 1rem;
`

const DragAndDropArea = styled.div`
  position: relative;
  display: flex;
  flex-direciton: column;
  align-items: center;
  padding: 1rem;
  border: solid;
  border-radius: 0.5rem;
  border-color: #bfbfbf;
  margin: 1rem 0;
  width: 100%;
  min-height: 200px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 1rem 0 0.5rem;
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`

const StyledButton = styled(Button)`
  height: 2rem;
  max-width: 5rem;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  place-self: center end;
`

const DragAndDropIcon = styled(FontAwesomeIcon)`
  color: #bfbfbf;
  position: absolute;
  top: 10%;
  left: 45%;
  right: 45%;
`

const StyledP = styled.p`
  position: absolute;
  left: 35%;
  right: 35%;
  bottom: 0;
  text-align: center;
`

const FileNameField = styled.p`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  place-self: center start;
  margin-top: 1rem;
`

const ErrorField = styled.div`
  color: red;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
`

const LoginNag = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  padding: 1rem;
  min-height: 21rem;
`

const SubmitBox = ({ title, t }) => {
  const [exercise, setExercise] = useState("StructuredStreaming")
  const [file, setFile] = useState(null)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("No file uploaded")
  const loginStateContext = useContext(LoginStateContext)

  const options = [
    //     { label: 'Intro', value: 'Intro' },
    //     { label: 'RDD', value: 'RDD' },
    //     { label: 'dataframe', value: 'dataframe' },
    //     { label: 'Mllib', value: 'Mllib' },
    //     { label: 'Graphframes', value: 'Graphframes' },
    { label: "StructuredStreaming", value: "StructuredStreaming" },
    { label: "Extra", value: "Extra" },
  ]

  const handleChange = (e) => {
    setMessage("No file uploaded")
    setError("")
    setFile(null)
    const file = e.target.files[0]
    const fileNameParts = file.name.split(".")
    const extension = fileNameParts[fileNameParts.length - 1]
    if (extension === "ipynb") {
      setFile(e.target.files[0])
      setMessage(`File uploaded: ${file.name}`)
    } else {
      setError("The inputted file was not a Jupyter notebook (.ipynb)!")
    }
  }

  const handleExChange = (e) => {
    setExercise(e.target.value)
  }

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label>
        {label}
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    )
  }

  const submitFile = (e) => {
    e.preventDefault()
    if (file) {
      const data = new FormData()
      data.append("Exercise-Name", exercise)
      data.append("file", file)
      const url = "https://bigdata-2.cs.helsinki.fi:443/upload"
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken()}`,
          },
        })
        .then((res) => {
          setMessage("No file uploaded")
          setError("")
          setFile(null)
          switch (res.status) {
            case 200:
              setMessage("File submitted successfully!")
              break
            case 401:
              setError(
                "Authentication issue, please re-log in to the material.",
              )
              break
            case 413:
              setError(
                "The submitted file was too large. Try to make it smaller (e.g. remove unused code) before submitting again.",
              )
              break
            default:
              setError("Something went wrong with submitting the file.")
          }
        })
        .catch((error) => {
          setMessage("No file uploaded")
          setFile(null)
          setError(
            `Could not connect to the server. ${error.name}: ${error.message}`,
          )
        })
    } else {
      console.log("No valid file to submit!")
    }
  }

  return (
    <Container>
      <Header>
        <StyledIcon icon={faCloudUploadAlt} size="1x" />
        {title}
      </Header>
      {loginStateContext.loggedIn ? (
        <div>
          <Description>
            Please submit your answer for the assignment {title} as a Jupyter
            notebook here:
          </Description>

          <Dropdown
            label="Select the exercise"
            options={options}
            value={exercise}
            onChange={handleExChange}
          />

          <SubmitArea onSubmit={submitFile}>
            <DragAndDropArea>
              <DragAndDropIcon icon={faFileDownload} size="5x" />
              <StyledP>
                Drag and drop a file here or <b>Upload a file</b>
              </StyledP>
              <StyledInput
                type="file"
                accept=".ipynb"
                title=""
                value=""
                onChange={handleChange}
              />
            </DragAndDropArea>
            <FileNameField>{message}</FileNameField>
            <StyledButton
              variant={file ? "contained" : "disabled"}
              color="primary"
              type="submit"
            >
              Submit
            </StyledButton>
          </SubmitArea>
          {error && (
            <ErrorField>
              {error}
              <br />
              If this issue persists, please contact course assistance.
            </ErrorField>
          )}
        </div>
      ) : (
        <LoginNag>{t("loginForExercise")}</LoginNag>
      )}
    </Container>
  )
}

export default withTranslation("common")(SubmitBox)
